ul.smothscroll {
    position: fixed;
    bottom: 85px;
    right:24px;
    list-style: none;
    z-index: 99;
}
ul.smothscroll a {
    background-color: #4b424da8;
    width: 55px;
    height: 55px;
    line-height: 55px;
    border-radius:50%;
    display: block;
    text-align: center;
    color: #fff;
    background-size: 200%,1px;
    background-position: 0;
    border: 1px solid #fff;
}
ul.smothscroll a:hover {
    background-color: #C0B596;
    color: #fff;
}

@media(max-width:767px){
    ul.smothscroll a {
        width: 55px;
        height: 55px;
        line-height: 55px;
    }
}