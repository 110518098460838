.App {
	overflow: hidden;
}
::selection {
    background-color: #4B424D; /* Modifier la couleur de fond de la sélection */
    color: white; /* Modifier la couleur du texte de la sélection */
}

.page-wrapper {
	position: relative;
	overflow: hidden;
}

.sr-only {
	display: none !important;
	opacity: 0;
	visibility: hidden;
}
.metallic-effect {
	filter: saturate(100%) brightness(120%);
  
	/* Ajouter une ombre subtile */
	/* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	
	/* Utiliser un dégradé pour simuler les variations de couleur */
	/* background: linear-gradient(to bottom, #d3c3a2, #a89566); */
	
	/* Ajouter une texture métallique en arrière-plan */
	/* background-image: url('texture-metal.jpg'); */
  }
  .whitespace-no-wrap{
	white-space: nowrap;
  
  }
@-webkit-keyframes rotating {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes rotating {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.rotating {
	-webkit-animation: rotating 5s alternate infinite;
	animation: rotating 5s alternate infinite;
}


/*** back to top **/
.back-to-top {
	background-color: rgba(172, 141, 104, 0.7);
	width: 45px;
	height: 45px;
	line-height: 45px;
	border: 2px solid #AC8D68;
	border-radius: 45px;
	text-align: center;
	display: none;
	position: fixed;
	z-index: 999;
	right: 15px;
	bottom: 15px;
	transition: all 0.2s;
}

@media (max-width: 991px) {
	.back-to-top {
		width: 35px;
		height: 35px;
		line-height: 35px;
	}
}

.back-to-top:hover {
	background-color: #AC8D68;
}

.back-to-top i {
	font-size: 18px;
	font-size: calc-rem-value(18);
	color: #fff;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

/** for popup image ***/
.mfp-wrap {
	background-color: rgba(0, 0, 0, 0.9);
	z-index: 99999;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
	opacity: 0;
	-webkit-backface-visibility: hidden;
	transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
	opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
	opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
	opacity: 0;
}

/*** back to top **/
.back-to-top {
	background-color: rgba(172, 141, 104, 0.7);
	width: 45px;
	height: 45px;
	line-height: 45px;
	border: 2px solid #AC8D68;
	border-radius: 45px;
	text-align: center;
	display: none;
	position: fixed;
	z-index: 999;
	right: 15px;
	bottom: 15px;
	transition: all 0.2s;
}

@media (max-width: 991px) {
	.back-to-top {
		width: 35px;
		height: 35px;
		line-height: 35px;
	}
}

.back-to-top:hover {
	background-color: #AC8D68;
}

.back-to-top i {
	font-size: 18px;
	font-size: calc-rem-value(18);
	color: #fff;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

/** for popup image ***/
.mfp-wrap {
	background-color: rgba(0, 0, 0, 0.9);
	z-index: 99999;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
	opacity: 0;
	-webkit-backface-visibility: hidden;
	transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
	opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
	opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
	opacity: 0;
}

/** for popup image ***/
.mfp-wrap {
	background-color: transparentize(red, 0.1);
	z-index: 99999;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
	opacity: 0;
	-webkit-backface-visibility: hidden;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
	opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
	opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
	opacity: 0;
}

/*** for fancybox video ***/
.fancybox-overlay {
	background: rgba(0, 0, 0, 0.9);
	z-index: 9999 !important;
}

.fancybox-wrap {
	z-index: 99999 !important;
}


.preloader {
	background-color: #4b424d;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 1000;
	overflow: hidden;
}

.preloader .vertical-centered-box {
	position: absolute;
	width: 100%;
	height: 100%;
	text-align: center;
}

.preloader .vertical-centered-box:after {
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
	margin-right: -0.25em;
}

.preloader .vertical-centered-box .content {
	box-sizing: border-box;
	display: inline-block;
	vertical-align: middle;
	text-align: left;
	font-size: 0;
}

.preloader * {
	transition: all 0.3s;
}

.preloader .loader-circle {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 120px;
	height: 120px;
	border-radius: 50%;
	box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
	margin-left: -60px;
	margin-top: -60px;
}

.preloader .loader-line-mask {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 60px;
	height: 120px;
	margin-left: -60px;
	margin-top: -60px;
	overflow: hidden;
	transform-origin: 60px 60px;
	-webkit-mask-image: -webkit-linear-gradient(top, #000000, rgba(0, 0, 0, 0));
	-webkit-animation: rotate 1.2s infinite linear;
	animation: rotate 1.2s infinite linear;
}

.preloader .loader-line-mask .loader-line {
	width: 120px;
	height: 120px;
	border-radius: 50%;
	box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}

.preloader #particles-background,
.preloader #particles-foreground {
	left: -51%;
	top: -51%;
	width: 202%;
	height: 202%;
	transform: scale3d(0.5, 0.5, 1);
}

.preloader #particles-background {
	background: #2c2d44;
	background-image: linear-gradient(45deg, #3f3251 2%, #002025 100%);
}

.preloader lesshat-selector {
	-lh-property: 0;
}

@-webkit-keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.preloader [not-existing] {
	zoom: 1;
}

.preloader lesshat-selector {
	-lh-property: 0;
}

@-webkit-keyframes fade {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.25;
	}
}

@keyframes fade {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.25;
	}
}

.preloader [not-existing] {
	zoom: 1;
}

.preloader lesshat-selector {
	-lh-property: 0;
}

@-webkit-keyframes fade-in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.preloader [not-existing] {
	zoom: 1;
}

/* mobile menu css */


#dl-menu li a {
	color: #fff !important;
}

#dl-menu li a::after {
	color: #fff !important;
}

#dl-menu li a:hover {
	background-color: rgba(0, 0, 0, 0.2);
}

.dl-menuwrapper li>a:not(:only-child):after {
	font-family: "themify";
	content: "";
	font-size: 10px;
}

.dl-menuwrapper li.dl-back:after {
	font-family: "themify";
	content: "";
	font-size: 10px;
}

.dl-menuwrapper button {
	background-color: #3757f7;
}

.dl-back::after {
	color: #fff !important;
}

#dl-menu ul {
	top: 65px;
	margin: 0;
	left: 0;
}

@media (max-width: 767px) {
	#dl-menu ul {
		top: 64px;
	}
}

@media (max-width: 375px) {
	#dl-menu ul {
		top: 65px;
	}
}

@media screen and (min-width: 992px) {
	#dl-menu {
		display: none;
	}
}

#dl-menu .dl-trigger {
	right: -20px;
	border-radius: 5px;
}

@media (max-width:575px) {
	#dl-menu .dl-trigger {
		right: -13px;
	}
}




@font-face {
	font-family: 'icomoon';
	src: url('../fonts/icomoon.eot');
	src: url('../fonts/icomoon.eot?#iefix') format('embedded-opentype'),
		url('../fonts/icomoon.woff') format('woff'),
		url('../fonts/icomoon.ttf') format('truetype'),
		url('../fonts/icomoon.svg#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

/* Common styles of menus */

.dl-menuwrapper {
	width: 10%;
	float: left;
	position: relative;
	-webkit-perspective: 1000px;
	perspective: 1000px;
	-webkit-perspective-origin: 50% 200%;
	perspective-origin: 50% 200%;
	z-index: 111;
}

@media (max-width:549px) {
	.dl-menuwrapper {
		width: 1%;
	}
}



.dl-menuwrapper button {
	text-indent: -900em;
	overflow: hidden;
	cursor: pointer;
	background-color: #C0B596;
	width: 40px;
	height: 40px;
	border: 0;
	outline: 0;
	position: relative;
}

button.dl-trigger {
	background-color: #C0B596;
}

.dl-menuwrapper button.dl-active,
.dl-menuwrapper ul {
	background: #C0B596;
}

.dl-menuwrapper button.dl-active {
	background-color: #C0B596;
}



.dl-menuwrapper button:after {
	content: '';
	position: absolute;
	width: 68%;
	height: 5px;
	background: #fff;
	top: 8px;
	left: 16%;
	box-shadow:
		0 10px 0 #fff,
		0 20px 0 #fff;
}

.dl-menuwrapper ul {
	padding: 0;
	list-style: none;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.dl-menuwrapper li {
	position: relative;
}

.dl-menuwrapper li a {
	display: block;
	position: relative;
	padding: 15px 20px;
	font-size: 16px;
	line-height: 20px;
	font-weight: 300;
	color: #fff;
	outline: none;
}

.no-touch .dl-menuwrapper li a:hover {
	background: rgba(255, 248, 213, 0.1);
}

.dl-menuwrapper li.dl-back>a {
	padding-left: 30px;
	background: rgba(0, 0, 0, 0.1);
}

.dl-menuwrapper li.dl-back:after,
.dl-menuwrapper li>a:not(:only-child):after {
	position: absolute;
	top: 0;
	line-height: 50px;
	font-family: 'themify';
	speak: none;
	-webkit-font-smoothing: antialiased;
	content: "\e628";
}

.dl-menuwrapper li.dl-back:after {
	left: 10px;
	color: rgba(212, 204, 198, 0.3);
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.dl-menuwrapper li>a:after {
	right: 10px;
	color: rgba(0, 0, 0, 0.15);
}

.dl-menuwrapper .dl-menu {
	margin: 5px 0 0 0;
	position: absolute;
	width: 100%;
	opacity: 0;
	pointer-events: none;
	-webkit-transform: translateY(10px);
	transform: translateY(10px);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.dl-menuwrapper .dl-menu.dl-menu-toggle {
	transition: all 0.3s ease;
}

.dl-menuwrapper .dl-menu.dl-menuopen {
	width: 250px;
	opacity: 1;
	pointer-events: auto;
	-webkit-transform: translateY(0px);
	transform: translateY(0px);
}

/* Hide the inner submenus */
.dl-menuwrapper li .dl-submenu {
	display: none;
}

/* 
When a submenu is openend, we will hide all li siblings.
For that we give a class to the parent menu called "dl-subview".
We also hide the submenu link. 
The opened submenu will get the class "dl-subviewopen".
All this is done for any sub-level being entered.
*/
.dl-menu.dl-subview li,
.dl-menu.dl-subview li.dl-subviewopen>a,
.dl-menu.dl-subview li.dl-subview>a {
	display: none;
}

.dl-menu.dl-subview li.dl-subview,
.dl-menu.dl-subview li.dl-subview .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen,
.dl-menu.dl-subview li.dl-subviewopen>.dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen>.dl-submenu>li {
	display: block;
}

/* Dynamically added submenu outside of the menu context */
.dl-menuwrapper>.dl-submenu {
	position: absolute;
	width: 100%;
	top: 50px;
	left: 0;
	margin: 0;
}

/* Animation classes for moving out and in */

.dl-menu.dl-animate-out-1 {
	-webkit-animation: MenuAnimOut1 0.4s;
	animation: MenuAnimOut1 0.4s;
}

.dl-menu.dl-animate-out-2 {
	-webkit-animation: MenuAnimOut2 0.3s ease-in-out;
	animation: MenuAnimOut2 0.3s ease-in-out;
}

.dl-menu.dl-animate-out-3 {
	-webkit-animation: MenuAnimOut3 0.4s ease;
	animation: MenuAnimOut3 0.4s ease;
}

.dl-menu.dl-animate-out-4 {
	-webkit-animation: MenuAnimOut4 0.4s ease;
	animation: MenuAnimOut4 0.4s ease;
}

.dl-menu.dl-animate-out-5 {
	-webkit-animation: MenuAnimOut5 0.4s ease;
	animation: MenuAnimOut5 0.4s ease;
}

@-webkit-keyframes MenuAnimOut1 {
	0% {}

	50% {
		-webkit-transform: translateZ(-250px) rotateY(30deg);
	}

	75% {
		-webkit-transform: translateZ(-372.5px) rotateY(15deg);
		opacity: .5;
	}

	100% {
		-webkit-transform: translateZ(-500px) rotateY(0deg);
		opacity: 0;
	}
}

@-webkit-keyframes MenuAnimOut2 {
	0% {}

	100% {
		-webkit-transform: translateX(-100%);
		opacity: 0;
	}
}

@-webkit-keyframes MenuAnimOut3 {
	0% {}

	100% {
		-webkit-transform: translateZ(300px);
		opacity: 0;
	}
}

@-webkit-keyframes MenuAnimOut4 {
	0% {}

	100% {
		-webkit-transform: translateZ(-300px);
		opacity: 0;
	}
}

@-webkit-keyframes MenuAnimOut5 {
	0% {}

	100% {
		-webkit-transform: translateY(40%);
		opacity: 0;
	}
}

@keyframes MenuAnimOut1 {
	0% {}

	50% {
		-webkit-transform: translateZ(-250px) rotateY(30deg);
		transform: translateZ(-250px) rotateY(30deg);
	}

	75% {
		-webkit-transform: translateZ(-372.5px) rotateY(15deg);
		transform: translateZ(-372.5px) rotateY(15deg);
		opacity: .5;
	}

	100% {
		-webkit-transform: translateZ(-500px) rotateY(0deg);
		transform: translateZ(-500px) rotateY(0deg);
		opacity: 0;
	}
}

@keyframes MenuAnimOut2 {
	0% {}

	100% {
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
		opacity: 0;
	}
}

@keyframes MenuAnimOut3 {
	0% {}

	100% {
		-webkit-transform: translateZ(300px);
		transform: translateZ(300px);
		opacity: 0;
	}
}

@keyframes MenuAnimOut4 {
	0% {}

	100% {
		-webkit-transform: translateZ(-300px);
		transform: translateZ(-300px);
		opacity: 0;
	}
}

@keyframes MenuAnimOut5 {
	0% {}

	100% {
		-webkit-transform: translateY(40%);
		transform: translateY(40%);
		opacity: 0;
	}
}

.dl-menu.dl-animate-in-1 {
	-webkit-animation: MenuAnimIn1 0.3s;
	animation: MenuAnimIn1 0.3s;
}

.dl-menu.dl-animate-in-2 {
	-webkit-animation: MenuAnimIn2 0.3s ease-in-out;
	animation: MenuAnimIn2 0.3s ease-in-out;
}

.dl-menu.dl-animate-in-3 {
	-webkit-animation: MenuAnimIn3 0.4s ease;
	animation: MenuAnimIn3 0.4s ease;
}

.dl-menu.dl-animate-in-4 {
	-webkit-animation: MenuAnimIn4 0.4s ease;
	animation: MenuAnimIn4 0.4s ease;
}

.dl-menu.dl-animate-in-5 {
	-webkit-animation: MenuAnimIn5 0.4s ease;
	animation: MenuAnimIn5 0.4s ease;
}

@-webkit-keyframes MenuAnimIn1 {
	0% {
		-webkit-transform: translateZ(-500px) rotateY(0deg);
		opacity: 0;
	}

	20% {
		-webkit-transform: translateZ(-250px) rotateY(30deg);
		opacity: 0.5;
	}

	100% {
		-webkit-transform: translateZ(0px) rotateY(0deg);
		opacity: 1;
	}
}

@-webkit-keyframes MenuAnimIn2 {
	0% {
		-webkit-transform: translateX(-100%);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateX(0px);
		opacity: 1;
	}
}

@-webkit-keyframes MenuAnimIn3 {
	0% {
		-webkit-transform: translateZ(300px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateZ(0px);
		opacity: 1;
	}
}

@-webkit-keyframes MenuAnimIn4 {
	0% {
		-webkit-transform: translateZ(-300px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateZ(0px);
		opacity: 1;
	}
}

@-webkit-keyframes MenuAnimIn5 {
	0% {
		-webkit-transform: translateY(40%);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0);
		opacity: 1;
	}
}

@keyframes MenuAnimIn1 {
	0% {
		-webkit-transform: translateZ(-500px) rotateY(0deg);
		transform: translateZ(-500px) rotateY(0deg);
		opacity: 0;
	}

	20% {
		-webkit-transform: translateZ(-250px) rotateY(30deg);
		transform: translateZ(-250px) rotateY(30deg);
		opacity: 0.5;
	}

	100% {
		-webkit-transform: translateZ(0px) rotateY(0deg);
		transform: translateZ(0px) rotateY(0deg);
		opacity: 1;
	}
}

@keyframes MenuAnimIn2 {
	0% {
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateX(0px);
		transform: translateX(0px);
		opacity: 1;
	}
}

@keyframes MenuAnimIn3 {
	0% {
		-webkit-transform: translateZ(300px);
		transform: translateZ(300px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateZ(0px);
		transform: translateZ(0px);
		opacity: 1;
	}
}

@keyframes MenuAnimIn4 {
	0% {
		-webkit-transform: translateZ(-300px);
		transform: translateZ(-300px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateZ(0px);
		transform: translateZ(0px);
		opacity: 1;
	}
}

@keyframes MenuAnimIn5 {
	0% {
		-webkit-transform: translateY(40%);
		transform: translateY(40%);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

.dl-menuwrapper>.dl-submenu.dl-animate-in-1 {
	-webkit-animation: SubMenuAnimIn1 0.4s ease;
	animation: SubMenuAnimIn1 0.4s ease;
}

.dl-menuwrapper>.dl-submenu.dl-animate-in-2 {
	-webkit-animation: SubMenuAnimIn2 0.3s ease-in-out;
	animation: SubMenuAnimIn2 0.3s ease-in-out;
}

.dl-menuwrapper>.dl-submenu.dl-animate-in-3 {
	-webkit-animation: SubMenuAnimIn3 0.4s ease;
	animation: SubMenuAnimIn3 0.4s ease;
}

.dl-menuwrapper>.dl-submenu.dl-animate-in-4 {
	-webkit-animation: SubMenuAnimIn4 0.4s ease;
	animation: SubMenuAnimIn4 0.4s ease;
}

.dl-menuwrapper>.dl-submenu.dl-animate-in-5 {
	-webkit-animation: SubMenuAnimIn5 0.4s ease;
	animation: SubMenuAnimIn5 0.4s ease;
}

@-webkit-keyframes SubMenuAnimIn1 {
	0% {
		-webkit-transform: translateX(50%);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateX(0px);
		opacity: 1;
	}
}

@-webkit-keyframes SubMenuAnimIn2 {
	0% {
		-webkit-transform: translateX(100%);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateX(0px);
		opacity: 1;
	}
}

@-webkit-keyframes SubMenuAnimIn3 {
	0% {
		-webkit-transform: translateZ(-300px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateZ(0px);
		opacity: 1;
	}
}

@-webkit-keyframes SubMenuAnimIn4 {
	0% {
		-webkit-transform: translateZ(300px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateZ(0px);
		opacity: 1;
	}
}

@-webkit-keyframes SubMenuAnimIn5 {
	0% {
		-webkit-transform: translateZ(-200px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateZ(0);
		opacity: 1;
	}
}

@keyframes SubMenuAnimIn1 {
	0% {
		-webkit-transform: translateX(50%);
		transform: translateX(50%);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateX(0px);
		transform: translateX(0px);
		opacity: 1;
	}
}

@keyframes SubMenuAnimIn2 {
	0% {
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateX(0px);
		transform: translateX(0px);
		opacity: 1;
	}
}

@keyframes SubMenuAnimIn3 {
	0% {
		-webkit-transform: translateZ(-300px);
		transform: translateZ(-300px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateZ(0px);
		transform: translateZ(0px);
		opacity: 1;
	}
}

@keyframes SubMenuAnimIn4 {
	0% {
		-webkit-transform: translateZ(300px);
		transform: translateZ(300px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateZ(0px);
		transform: translateZ(0px);
		opacity: 1;
	}
}

@keyframes SubMenuAnimIn5 {
	0% {
		-webkit-transform: translateZ(-200px);
		transform: translateZ(-200px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		opacity: 1;
	}
}

.dl-menuwrapper>.dl-submenu.dl-animate-out-1 {
	-webkit-animation: SubMenuAnimOut1 0.4s ease;
	animation: SubMenuAnimOut1 0.4s ease;
}

.dl-menuwrapper>.dl-submenu.dl-animate-out-2 {
	-webkit-animation: SubMenuAnimOut2 0.3s ease-in-out;
	animation: SubMenuAnimOut2 0.3s ease-in-out;
}

.dl-menuwrapper>.dl-submenu.dl-animate-out-3 {
	-webkit-animation: SubMenuAnimOut3 0.4s ease;
	animation: SubMenuAnimOut3 0.4s ease;
}

.dl-menuwrapper>.dl-submenu.dl-animate-out-4 {
	-webkit-animation: SubMenuAnimOut4 0.4s ease;
	animation: SubMenuAnimOut4 0.4s ease;
}

.dl-menuwrapper>.dl-submenu.dl-animate-out-5 {
	-webkit-animation: SubMenuAnimOut5 0.4s ease;
	animation: SubMenuAnimOut5 0.4s ease;
}

@-webkit-keyframes SubMenuAnimOut1 {
	0% {
		-webkit-transform: translateX(0%);
		opacity: 1;
	}

	100% {
		-webkit-transform: translateX(50%);
		opacity: 0;
	}
}

@-webkit-keyframes SubMenuAnimOut2 {
	0% {
		-webkit-transform: translateX(0%);
		opacity: 1;
	}

	100% {
		-webkit-transform: translateX(100%);
		opacity: 0;
	}
}

@-webkit-keyframes SubMenuAnimOut3 {
	0% {
		-webkit-transform: translateZ(0px);
		opacity: 1;
	}

	100% {
		-webkit-transform: translateZ(-300px);
		opacity: 0;
	}
}

@-webkit-keyframes SubMenuAnimOut4 {
	0% {
		-webkit-transform: translateZ(0px);
		opacity: 1;
	}

	100% {
		-webkit-transform: translateZ(300px);
		opacity: 0;
	}
}

@-webkit-keyframes SubMenuAnimOut5 {
	0% {
		-webkit-transform: translateZ(0);
		opacity: 1;
	}

	100% {
		-webkit-transform: translateZ(-200px);
		opacity: 0;
	}
}

@keyframes SubMenuAnimOut1 {
	0% {
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
		opacity: 1;
	}

	100% {
		-webkit-transform: translateX(50%);
		transform: translateX(50%);
		opacity: 0;
	}
}

@keyframes SubMenuAnimOut2 {
	0% {
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
		opacity: 1;
	}

	100% {
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
		opacity: 0;
	}
}

@keyframes SubMenuAnimOut3 {
	0% {
		-webkit-transform: translateZ(0px);
		transform: translateZ(0px);
		opacity: 1;
	}

	100% {
		-webkit-transform: translateZ(-300px);
		transform: translateZ(-300px);
		opacity: 0;
	}
}

@keyframes SubMenuAnimOut4 {
	0% {
		-webkit-transform: translateZ(0px);
		transform: translateZ(0px);
		opacity: 1;
	}

	100% {
		-webkit-transform: translateZ(300px);
		transform: translateZ(300px);
		opacity: 0;
	}
}

@keyframes SubMenuAnimOut5 {
	0% {
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		opacity: 1;
	}

	100% {
		-webkit-transform: translateZ(-200px);
		transform: translateZ(-200px);
		opacity: 0;
	}
}

/* No JS Fallback */
.no-js .dl-menuwrapper .dl-menu {
	position: relative;
	opacity: 1;
	-webkit-transform: none;
	transform: none;
}

.no-js .dl-menuwrapper li .dl-submenu {
	display: block;
}

.no-js .dl-menuwrapper li.dl-back {
	display: none;
}

.no-js .dl-menuwrapper li>a:not(:only-child) {
	background: rgba(0, 0, 0, 0.1);
}

.no-js .dl-menuwrapper li>a:not(:only-child):after {
	content: '';
}

/* Colors for demos */

/* Demo 1 */
.demo-1 .dl-menuwrapper button {
	background: #c62860;
}

.demo-1 .dl-menuwrapper button:hover,
.demo-1 .dl-menuwrapper button.dl-active,
.demo-1 .dl-menuwrapper ul {
	background: #9e1847;
}

/* Demo 2 */
.demo-2 .dl-menuwrapper button {
	background: #e86814;
}

.demo-2 .dl-menuwrapper button:hover,
.demo-2 .dl-menuwrapper button.dl-active,
.demo-2 .dl-menuwrapper ul {
	background: #D35400;
}

/* Demo 3 */
.demo-3 .dl-menuwrapper button {
	background: #08cbc4;
}

.demo-3 .dl-menuwrapper button:hover,
.demo-3 .dl-menuwrapper button.dl-active,
.demo-3 .dl-menuwrapper ul {
	background: #00b4ae;
}

/* Demo 4 */
.demo-4 .dl-menuwrapper button {
	background: #90b912;
}

.demo-4 .dl-menuwrapper button:hover,
.demo-4 .dl-menuwrapper button.dl-active,
.demo-4 .dl-menuwrapper ul {
	background: #79a002;
}

/* Demo 5 */
.demo-5 .dl-menuwrapper button {
	background: #744783;
}

.demo-5 .dl-menuwrapper button:hover,
.demo-5 .dl-menuwrapper button.dl-active,
.demo-5 .dl-menuwrapper ul {
	background: #643771;
}


.dl-menu.dl-animate-out-1 {
	animation: MenuAnimOut1 0.4s linear forwards;
}

@keyframes MenuAnimOut1 {
	50% {
		transform: translateZ(-250px) rotateY(30deg);
	}

	75% {
		transform: translateZ(-372.5px) rotateY(15deg);
		opacity: .5;
	}

	100% {
		transform: translateZ(-500px) rotateY(0deg);
		opacity: 0;
	}
}

.dl-menu.dl-animate-in-1 {
	animation: MenuAnimIn1 0.3s linear forwards;
}

@keyframes MenuAnimIn1 {
	0% {
		transform: translateZ(-500px) rotateY(0deg);
		opacity: 0;
	}

	20% {
		transform: translateZ(-250px) rotateY(30deg);
		opacity: 0.5;
	}

	100% {
		transform: translateZ(0px) rotateY(0deg);
		opacity: 1;
	}
}


.theme-btn {
	color: #fff;
	display: inline-block;
	font-weight: 600;
	border: 0;
	text-transform: capitalize;
	-webkit-backface-visibility: hidden;
	z-index: 1;
	position: relative;
	transition: all 0.5s ease-out;
	font-size: 14px;
	background: none;
	padding: 8px 16px;
	border: 1px solid #C0B596;
	border-radius: 30px;
}



.theme-btn:after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	border-radius: 40px;
	width: 100%;
	height: 100%;
	background: #C0B596;
	transition: all 0.5s ease-out;
	opacity: 0;
	z-index: -1;
}

.theme-btn:hover,
.theme-btn:focus,
.theme-btn:active {
	background: #C0B596;
	color: #fff;
}

.theme-btn:hover:after {
	opacity: 1;
}

@media (max-width: 991px) {
	.theme-btn {
		font-size: 16px;
		padding: 12px 30px;
	}
}

@media (max-width: 767px) {
	.theme-btn {
		padding: 10px 18px;
		font-size: 14px;
	}

	.theme-btn:before {
		right: 18px;
	}
}

@media (max-width: 399px) {
	.theme-btn {
		padding: 8px 10px;
	}

	.theme-btn:before {
		right: 10px;
	}
}

/* search css */
.header-search-form.header-search-content-toggle {
	opacity: 1;
	visibility: visible;
	top: 260%;
}


@media (max-width:499px) {
	.logo {
		position: relative;
		right: -15px;
	}
}

@media (max-width:399px) {
	.logo {
		position: relative;
		right: -25px;
	}
}

/* hero slider css */

.slide::after {
	 background: -moz-linear-gradient(left, #4b424d 14%, rgba(27, 32, 53, 0.82) 15%, rgba(138, 140, 151, 0) 33%, rgba(255, 255, 255, 0) 52%, rgba(244, 244, 244, 0) 55%, rgba(166, 167, 175, 0) 68%, rgba(75, 79, 96, 0.86) 83%, rgba(21, 26, 48, 0.86) 92%);
	background: -webkit-linear-gradient(left, rgba(21, 26, 48, 0.82) 14%, rgba(27, 32, 53, 0.82) 15%, rgba(138, 140, 151, 0) 33%, rgba(255, 255, 255, 0) 52%, rgba(244, 244, 244, 0) 55%, rgba(166, 167, 175, 0) 68%, rgba(75, 79, 96, 0.86) 83%, rgba(21, 26, 48, 0.86) 92%); 
	background: linear-gradient(to right,   #363636 4%, rgba(27, 32, 53, 0.82) 15%, rgba(119, 120, 124, 0) 33%, rgba(255, 255, 255, 0) 52%, rgba(244, 244, 244, 0) 55%, rgba(166, 175, 171, 0) 68%, rgba(78, 97, 94, 0.86) 83%, rgba(26, 39, 25, 0.86) 92%);
	 background: linear-gradient(to right, #4b424d 4%, #353433 15%, #0c0c0c 33%, #111111 52%, #1a1919 55%, #141414 68%, #aba8a7 85%, #4b424d 97%); 

	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#363636', endColorstr='#ffffff', GradientType=2); 
	
}
/* .slide::after {
	background: -moz-linear-gradient(left, #545758 2%, rgba(84, 87, 88, 0.82) 100%);
	background: -webkit-linear-gradient(left, rgba(84, 87, 88, 0.82) 2%, rgba(84, 87, 88, 0) 100%);
	background: linear-gradient(to right, #545758 2%, #266e80 100%, left, rgba(84, 87, 88, 0.82) 2%, rgba(239, 241, 241, 0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d621545758', endColorstr='#d621545758', GradientType=1);
} */
.text-gradient {
    background:linear-gradient(to bottom, #ded080de, #fbdf99);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent; /* Cela rendra le texte invisible, mais le dégradé sera visible */
}
.slide {
	position: relative;
	/* background: rgb(117,228,221);
background: radial-gradient(circle, rgba(117,228,221,0.7847514005602241) 8%, rgba(59,222,212,0.5578606442577031) 35%, rgba(0,254,255,0.4290091036414566) 100%); */
}

.slick-dotted.slick-slider {
	margin-bottom: 0px;
}

.slide-caption {
	z-index: 19;
}



.hero .slide .slider-bg {
	display: none;
}

.hero .slide:focus {
	outline: none;
}

.hero .slide .container {
	height: 100%;
	display: table;
	padding: 0;
}

.hero .slide .row {
	display: table-cell;
	vertical-align: middle;
}

.hero .swiper-button-prev,
.hero .swiper-button-next {
	background: rgba(255, 255, 255, 0.2);
	width: 60px;
	height: 60px;
	line-height: 60px;
	border: 2px solid #fff;
	border-radius: 50%;
	opacity: 0.7;
	text-align: center;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}

.hero .swiper-button-prev:hover,
.hero .swiper-button-next:hover {
	opacity: 0.9;
}

@media (max-width: 767px) {

	.hero .swiper-button-prev,
	.hero .swiper-button-next {
		display: none;
	}
}

.hero .swiper-button-prev:after,
.hero .swiper-button-next:after {
	display: none;
}

.hero .swiper-button-prev {
	left: 25px;
}

.hero .swiper-button-prev:before {
	font-family: "themify";
	content: "\e629";
	font-size: 20px;
	color: #fff;
}

.hero .swiper-button-next {
	right: 25px;
}

.hero .swiper-button-next:before {
	font-family: "themify";
	content: "\e628";
	font-size: 20px;
	color: #fff;
}

@media (max-width: 991px) {

	.hero .swiper-button-prev,
	.hero .swiper-button-next {
		display: none;
	}
}

.page-title:after {
	content: "";
	background: -moz-linear-gradient(left,  #312a32b3 1%, #4b424d5d 46%, rgba(45, 21, 48, 0.07) 95%, rgba(48, 21, 40, 0) 100%);
	background: -webkit-linear-gradient(left, #312a32b3 1%, #4b424d5d 46%, rgba(48, 21, 48, 0.07) 95%, rgba(43, 21, 48, 0) 100%);
	background: linear-gradient(to right, #312a32b3 1%, #4b424d5d 46%, rgba(48, 21, 48, 0) 95%, rgba(43, 21, 48, 0) 100%);
	
	/* background: -moz-linear-gradient(left,  rgb(0 14 7 / 79%) 1%, rgb(3 25 20 / 74%) 46%, rgb(21 48 23 / 7%) 95%, rgba(21, 26, 48, 0) 100%);
	background: -webkit-linear-gradient(left, rgb(0 14 7 / 79%) 1%, rgb(3 25 20 / 74%) 46%, rgb(21 48 23 / 7%) 95%, rgba(21, 26, 48, 0) 100%);
	background: linear-gradient(to right, rgb(3 8 24 / 76%) 1%, rgb(7 3 25 / 53%) 46%, rgb(21 48 23 / 0%) 95%, rgb(21 48 43 / 0%) 100%); */

	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4b424db3', endColorstr='#27002830', GradientType=1);
	/* filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c9152830', endColorstr='#00152830', GradientType=1); */
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: -30;
}
.smarald-gradient{
	/* background-image: radial-gradient(ellipse farthest-corner at top, rgba(22, 70, 78, 1) 20%, rgba(0, 48, 55, 0.95) 40%, rgba(0, 27, 34, 0.9) 60%, rgba(0, 0, 11, 0.9) 100%, rgba(0, 0, 0, 0.95) 95%);  */
	background-image: linear-gradient(180deg, rgb(22 70 78 / 21%) 20%, rgb(0 48 55 / 25%) 40%, rgb(0 27 34) 60%, rgb(0 0 11) 100%, rgb(0 0 0) 95%);
}

/* start testimonial css */

.testimonial-slide .owl-nav div {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	height: 30px;
	width: 40px;
	line-height: 30px;
	text-align: center;
}

.testimonial-slide .owl-nav div.owl-next {
	right: 0;
	left: auto;
}

.testimonial-slide .owl-dots {
	position: absolute;
	bottom: 5%;
	right: 7%;

}

.testimonial-slide .owl-dot {
	display: inline-block;
	height: 10px;
	width: 10px;
	background: #bbb;
	border-radius: 50%;
	margin: 0px 5px;
}

.testimonial-slide .owl-dot.active {
	background: #C0B596;
}


@media (max-width:991px) {
	.testimonial-slide .owl-dots {
		right: 0%;
	}
}

@media (max-width:767px) {
	.testimonial-slide .owl-dots {
		position: absolute;
		left: 4%;
		text-align: center;
	}
}

@media (max-width:575px) {
	.testimonial-slide .owl-dots {
		position: absolute;
		bottom: 20px;
		left: 4%;
	}
}








/* team slider css  */

.attorney-sec .slick-list {
	margin: 0 -6px;
}

.attorney-sec .slick-slide {
	padding: 0 3px;
}

.attorney-sec .slick-prev, .attorney-sec .slick-next {
	background: #fff;
	width: 50px;
	height: 50px;
	line-height: 65px;
	transition: all 0.3s;
	border: 1px solid #C0B596;
	border-radius: 50%;
	font-size: 0px;
	z-index: 11;
}
.attorney-sec .slick-prev:hover, .attorney-sec .slick-next:hover {
	background-color: #C0B596;
}
.attorney-sec .slick-prev:hover:before, .attorney-sec .slick-next:hover:before {
	color: #fff;
}
@media (max-width: 1440px) {
	.attorney-sec .slick-prev, .attorney-sec .slick-next {
		opacity: 0;
		visibility: hidden;
   }
}
@media (max-width: 767px) {
	.attorney-sec .slick-prev, .attorney-sec .slick-next {
		display: none;
   }
}
.attorney-sec .slick-prev {
	left: -100px;
}
@media (max-width: 1440px) {
	.attorney-sec .slick-prev {
		left: 20px;
   }
}
.attorney-sec .slick-prev:before {
	font-family: "themify";
	content: "\e629";
	opacity: 1;
	color: #C0B596;
}
.attorney-sec .slick-next {
	right: -100px;
}
@media (max-width: 1440px) {
	.attorney-sec .slick-next {
		right: 20px;
   }
}
.attorney-sec .slick-next:before {
	font-family: "themify";
	content: "\e628";
	opacity: 1;
	color: #C0B596;
}
.slick-dots {
	text-align: center;
}
@media (min-width: 767px) {
	.slick-dots {
		display: none !important;
   }
}
.slick-dots li {
	width: unset;
	height: unset;
}
.slick-dots li button {
	border: none;
	margin: 0 5px;
	width: 20px;
	height: 8px;
	border-radius: 10px;
	background: #ebebeb;
}
.slick-dots li button::before {
	display: none;
}
.slick-dots li.slick-active button {
	background: #C0B596;
	width: 45px;
}
:hover .attorney-sec .slick-prev, :hover .attorney-sec .slick-next {
	opacity: 1;
	visibility: visible;
}



/* blog css slider */
.post-slider .owl-controls {
	width: 100%;
	margin: 0;
	position: absolute;
	left: 0;
	top: 50%;
}

.post-slider .owl-nav [class*=owl-]:hover {
	background: #C0B596;
	color: #fff;
}

.post-slider .owl-nav [class*=owl-] {
	background: #f5f5f5;
	width: 50px;
	height: 50px;
	line-height: 58px;
	padding: 0;
	margin: 0;
	border-radius: 50%;
	color: #282e3f;
	transition: all .3s ease-in-out;
	border: 0;
}

.post-slider .fi::before {
	font-size: 20px;
}



.post-slider .owl-nav .owl-prev,
.post-slider .owl-nav .owl-next {
	position: absolute;
	top: 50%;
	@include translatingY();
}

.post-slider .owl-nav .owl-prev {
	left: 15px;
}

.post-slider .owl-nav .owl-next {
	right: 15px;
}




/* form css */
.contact-form ::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #bbb5a5;
	font-size: 14px;
}

.contact-form ::-moz-placeholder {
	/* Firefox 19+ */

	color: #bbb5a5;
	font-size: 14px;
}

.contact-form :-ms-input-placeholder {
	/* IE 10+ */
	color: #bbb5a5;
	font-size: 14px;
}

.contact-form :-moz-placeholder {
	/* Firefox 18- */

	color: #bbb5a5;
	font-size: 14px;
}


.contact-form select ::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #bbb5a5;
	font-size: 14px;
}

.contact-form select ::-moz-placeholder {
	/* Firefox 19+ */

	color: #bbb5a5;
	font-size: 14px;
}

.contact-form select :-ms-input-placeholder {
	/* IE 10+ */
	color: #bbb5a5;
	font-size: 14px;
}

.contact-form select :-moz-placeholder {
	/* Firefox 18- */

	color: #bbb5a5;
	font-size: 14px;
}






#email-error {
	color: red;
}

#service-error {
	color: red;
}

#name-error {
	color: red;
}


.error-handling-messages {
	width: 100% !important;
	margin-top: 15px !important;
}

label.error {
	color: red;
	font-size: calc-rem-value(14);
	font-weight: normal;
	margin: 5px 0 0 0;
	text-align: left;
	display: block;
}

#c-loader,
#loader {
	display: none;
	margin-top: 10px;
}

#c-loader i,
#loader i {
	font-size: 30px;
	color: #59C378;
	display: inline-block;
	-webkit-animation: rotating linear 2s infinite;
	animation: rotating linear 2s infinite;
}

#success,
#c-success,
#c-error,
#error {
	width: 100%;
	color: red;
	padding: 5px 10px;
	font-size: 16px;
	text-align: center;
	display: none;

}

@media (max-width:767px) {

	#success,
	#c-success,
	#c-error,
	#error {
		font-size: 15px;
	}
}

#c-success,
#success {
	background-color: lighten(green, 5%);
	border-left: 5px solid green;
	margin-bottom: 5px;
}

#c-error,
#error {
	background-color: lighten(#bbb5a5, 5%);
}



.unactive-color {
	color: #333;
	transition: all .3s ease-in-out;
}

.current {
	color: #C0B596;
}

.testimonial-slide .slick-dots {
	text-align: right;
}

.testimonial-slide .slick-dots li button:before {
	font-size: 10px;
	color: #C0B596;
}

@media(max-width:767px) {
	.testimonial-slide .slick-dots {
		display: none !important;
	}
}

.errorMessage {
	color: #C0B596;
}


.format-standard-image .video-btn{
  display: none;
}

.format-video .video-holder{
	position: relative;
}

.format-video .video-holder button.btn-wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 63px;
    height: 63px;
    line-height: 63px;
    background: #fff;
    border-radius: 50%;
    z-index: 1;
    border: 0;
	font-size: 30px;
	color: #AC8D68;
}

.format-video .video-holder button.btn-wrap:after {
    position: absolute;
    left: -12px;
    top: -12px;
    width: 140%;
    height: 140%;
    background: hsla(0,0%,100%,.3);
    content: "";
    animation: spineer 2s infinite;
    border-radius: 50%;
    z-index: -1;
}

@-webkit-keyframes spineer {
	from {
	  box-shadow: 0 0 0 0 rgba(192, 181, 150, 0.99);
	}
	to {
	  box-shadow: 0 0 0 45px rgba(192, 181, 150, 0.01);
	}
  }
  @keyframes spineer {
	from {
	  box-shadow: 0 0 0 0 rgba(192, 181, 150, 0.99);
	}
	to {
	  box-shadow: 0 0 0 45px rgba(192, 181, 150, 0.01);
	}
  }

  .fixed-navbar.active .wpo-site-header {
	position: fixed;
	z-index: 555;
	top: 0;
	left: 0;
	width: 100%;
	background: #4B424D;
	border: none;
	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  }

  .gallery-filters li.active span{
	color: #C0B596;
  }
  .responsivemenu .active {
    background: #C0B596;
}
.relative .fixed-navbar .active {
	background: #C0B596;
}